<template xmlns:wire="http://www.w3.org/1999/xhtml">
  <div class="">
    <div class="sticky top-0 bg-gray-50 border-b pb-1.5 z-20">
      <div class="flex flex-row items-center ml-4">
        <label id="listbox-label1" class="fixed block relative text-2xl text-o_purple-100 font-semibold mr-2">
          產品
        </label>
        <div class="relative inline-block text-left">
          <div>
            <button v-on:click="isActive1 = !isActive1" type="button"
                    class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
              {{ select_state1 }}
              <!-- Heroicon name: solid/chevron-down -->
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
          <!--
            Dropdown panel, show/hide based on dropdown state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          -->
          <div v-show="isActive1" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <a @click="selectProductEvent($event)"
                 v-for="product in productList" :key="product"
                 href="#" class="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                {{ product }}
              </a>
            </div>
          </div>
        </div>
        <button
          @click="checkAppConnection"
          type="button"
          class=" ml-3 font-semibold
              inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
          連線確認
        </button>
        <button
          v-if="selectedProduct"
          @click="commitVerify"
          type="button"
          class=" ml-3 font-semibold
              inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
          節點產生
        </button>
        <button
          v-if="selectedProduct"
          @click="commitVerify2"
          type="button"
          class=" ml-3 font-semibold
              inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
          樣式產生
        </button>
        <button
          v-if="selectedProduct"
          @click="refreshPageForNodeInfoAndGraph"
          type="button"
          class=" ml-3 font-semibold
              inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
          重新整理
        </button>
        <button
          v-if="selectedProduct"
          @click="switchGraph"
          type="button"
          class=" ml-3 font-semibold
              inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
          圖譜切換
        </button>
      </div>
    </div>
    <div class="flex p-1 px-4 border-b">
      <!-- 下面這個是測試看是否有抓到資料     -->
      <!--      <h1>Current message: ID 1 is edited successfully</h1>-->
      <!--      <p>{{ content }}</p>-->
      <label id="listbox-label2" class="block text-xl text-o_purple-100 font-semibold mr-2">
        應用程式網絡連線狀態:
      </label>
      <span v-if="!connected" class="px-4 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
        未連線
      </span>
      <span v-if="connected" class="px-4 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
        連線中
      </span>
    </div>
    <div v-show="isLoading" wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-80 flex flex-col items-center justify-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <h2 class="text-center text-white text-xl font-semibold">處理中...</h2>
      <p class="w-1/3 text-center text-white">這需要一些時間, 請不要關閉此頁面或做其他操作</p>
    </div>
    <form v-if="commitVerifyForm" @submit.prevent="generateNodes(select_state1)" class="space-y-8 divide-y divide-gray-200 fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-80 flex flex-col items-center justify-center">
      <div class="rounded bg-white max-w-md rounded overflow-hidden shadow-xl p-5 opacity-100">
        <div class="mb-5">
          <!--        <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />-->
          <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            確認過新紀錄產生新圖譜內容
          </h2>
        </div>
        <div class="rounded-md shadow-sm -space-y-px">
          <div class="grid gap-6">
            <!-- 這邊可以設置可以修改的項目，我是預定id、time不能改以及state，能修改的地方就要幫他新增一個temp data，ex: emailTemp -->
            <!--            <div class="col-span-12">-->
            <!--              <label for="first_name" class="block text-sm font-medium text-gray-700">First name</label>-->
            <!--              <input v-model="nameTemp" type="text" name="first_name" id="first_name" autocomplete="given-name" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-50 sm:text-sm">-->
            <!--            </div>-->
            <!--            <div class="col-span-12">-->
            <!--              <label for="email_address" class="block text-sm font-medium text-gray-700">Email address</label>-->
            <!--              <input v-model="emailTemp" type="text" name="email_address" id="email_address" autocomplete="email" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-50 sm:text-sm">-->
            <!--            </div>-->

            <div class="flex">
              <button @click="cancelCommitVerify" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                取消
              </button>
              <button type="submit" class="ml-1.5 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                產生
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <form v-if="commitVerifyForm2" @submit.prevent="generateStyles(select_state1)" class="space-y-8 divide-y divide-gray-200 fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-80 flex flex-col items-center justify-center">
      <div class="rounded bg-white max-w-md rounded overflow-hidden shadow-xl p-5 opacity-100">
        <div class="mb-5">
          <!--        <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />-->
          <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            確認過新紀錄產生新樣式內容
          </h2>
        </div>
        <div class="rounded-md shadow-sm -space-y-px">
          <div class="grid gap-6">
            <!-- 這邊可以設置可以修改的項目，我是預定id、time不能改以及state，能修改的地方就要幫他新增一個temp data，ex: emailTemp -->
            <!--            <div class="col-span-12">-->
            <!--              <label for="first_name" class="block text-sm font-medium text-gray-700">First name</label>-->
            <!--              <input v-model="nameTemp" type="text" name="first_name" id="first_name" autocomplete="given-name" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-50 sm:text-sm">-->
            <!--            </div>-->
            <!--            <div class="col-span-12">-->
            <!--              <label for="email_address" class="block text-sm font-medium text-gray-700">Email address</label>-->
            <!--              <input v-model="emailTemp" type="text" name="email_address" id="email_address" autocomplete="email" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-50 sm:text-sm">-->
            <!--            </div>-->

            <div class="flex">
              <button @click="cancelCommitVerify2" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                取消
              </button>
              <button type="submit" class="ml-1.5 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                產生
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="grid grid-cols-8 grid-rows-7 grid-flow-col gap-1.5 mt-5 custom-h-inside">
      <div class="nodeAndGraphScrollbar rounded-2xl bg-white shadow-2xl mx-2 my-2 col-span-3 row-span-7 overflow-auto p-6">
        <h1 class="text-o_purple-200 text-3xl font-semibold">節點詞彙</h1>
        <ul v-show="true" class="text-o_purple-100 text-3xl my-3">
          <li v-for="(nodeInfo, i) in nodeInfoList" :key="i" class="text-o_purple-100 text-3xl mb-4  mt-1 pt-4 border-b-4 border-t_dark_brown">
            <button @click="clickNodeEvent(nodeInfo.name)"
                    v-if="true"
                    type="button"
                    class=" font-semibold mb-4
              inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
              {{nodeInfo.name}}
            </button><br v-show="nodeInfo.is_term">
            <span v-show="nodeInfo.is_term" class="font-semibold">各國翻譯(中/英/日):</span><br>
            <div class="pb-3.5" v-show="nodeInfo.is_term">{{nodeInfo.chinese}}/{{nodeInfo.english}}/{{nodeInfo.japanese}} </div>
            <span class="font-semibold">內容:</span><br>
            <div class="pb-3.5">{{ nodeInfo.content }}</div>
          </li>
        </ul>
      </div>
      <div id="graph" class="nodeAndGraphScrollbar rounded-2xl bg-white shadow-2xl mx-2 my-2 col-span-5 row-span-full overflow-auto p-6" ref="canvas">
        <h1 class="text-o_purple-200 text-3xl font-semibold">知識圖譜</h1>
      </div>
<!--      <div class="rounded-2xl bg-white shadow-2xl mx-2 my-2 col-span-5 row-span-full overflow-auto p-6" >-->
<!--        <h1 class="text-o_purple-200 text-3xl font-semibold">知識圖譜</h1>-->
<!--        <div id="graph" class="rounded-2xl bg-white shadow-2xl mx-2 my-2 col-span-5 row-span-full overflow-auto" ref="canvas"></div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import Axios from 'axios'
import G6 from '@antv/g6'
import UserService from '../services/user.service'
import Config from '@/config/config'
let graphInstance = {}
export default {
  name: 'Node',
  methods: {
    async checkAppConnection () {
      this.isLoading = true
      await UserService.checkAppConnection().then(
        res => {
          this.connected = !(res.data.isErr)
        }
      ).catch(err => {
        // console.log('Connection err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      this.isLoading = false
    },
    // 這個會依據product去抓records產生nodes與新的圖譜連線
    // 下面這個有非同步問題，會有節點尚未產生完成，就跑去產生圖譜樣式的可能性，導致錯誤，因此目前先暫停使用此func
    // 因此拆成generateNodes 與generateStyles
    // async GetRecordAndRefreshBasedOnProduct () {
    //   this.isLoading = true
    //   await this.GenerateNewRecordAndGraph(this.select_state1)
    //   await this.ShowNodeInfoAndRecordWithProduct(this.select_state1)
    //   await this.GetGraph(this.select_state1, 2, 3, this.select_state1)
    //   this.isLoading = false
    // },
    // 下面這個有非同步問題，會有節點尚未產生完成，就跑去產生圖譜樣式的可能性，導致錯誤，因此目前先暫停使用此func
    // 因此拆成generateNodes 與generateStyles
    // async GenerateNewRecordAndGraph (product) {
    //   this.isLoading = true
    //   await UserService.GenerateNewNode(product).then(
    //     res => {
    //       this.contents = res.data.result
    //       // console.log(this.contents)
    //     }
    //   ).catch(err => {
    //     // console.log('generate new nodes error')
    //     if (err.response) {
    //       // Request made and server responded 請求成立且回應有回來，是錯誤情況
    //       // 若沒有要鎖住畫面則可以註解或清除掉
    //       this.isLoading = false
    //       return false
    //     } else if (err.request) {
    //       // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
    //       this.isLoading = false
    //       return false
    //     } else {
    //       // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
    //       this.isLoading = false
    //       return false
    //     }
    //   })
    //   await UserService.GenerateNewGraph(product).then(
    //     res => {
    //       this.contents = res.data.result
    //       // console.log(this.contents)
    //     }).catch(err => {
    //     // console.log('generate new graph structure and style error')
    //     if (err.response) {
    //       // Request made and server responded 請求成立且回應有回來，是錯誤情況
    //       // 若沒有要鎖住畫面則可以註解或清除掉
    //       this.isLoading = false
    //       return false
    //     } else if (err.request) {
    //       // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
    //       this.isLoading = false
    //       return false
    //     } else {
    //       // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
    //       this.isLoading = false
    //       return false
    //     }
    //   })
    //   this.isLoading = false
    // },
    async refreshPageForNodeInfoAndGraph () {
      this.isLoading = true
      await this.ShowNodeInfoAndRecordWithProduct(this.select_state1)
      await this.GetGraph(this.select_state1, 2, 3, this.select_state1)
      this.isLoading = false
    },
    async GetProductList () {
      const productListRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await productListRequest.get('/graph/product-list').then(res => {
        const resTemp = res.data
        // // console.log(resTemp.productList)
        // 這類型的async task最好不要採用回傳賦值得作法，很容易導致整格程序卡住
        this.productList = resTemp.result
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          return false
        }
      })
    },
    async ShowNodeInfoAndRecordWithProduct (product) {
      this.isLoading = true
      // // console.log(event.target.innerText)
      await this.GetNodeInfo(product)
      this.isLoading = false
    },
    async GetNodeInfo (product) {
      this.isLoading = true
      const dataForm = new FormData()
      this.nodeInfoList = []
      dataForm.append('product', product)
      const nodeInfoRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await nodeInfoRequest.post('/graph/node-info', dataForm).then(res => {
        const resTemp = res.data
        // 下面這條很特殊，若是我們沒有取得response等於他會變成空的，所以也會觸發，empty屬於false在js中，因此沒東西或者is_err=false都可以觸發
        // 而上面這巧合，也順帶因為進去result為空的因此幫我們清空，這是比較奇妙的處理方式
        if (!resTemp.is_err) {
          this.nodeInfoList = resTemp.result
        }
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      this.isLoading = false
    },
    async switchGraph () {
      graphInstance.read(this.current_graph_data)
    },
    async selectProductEvent (event) {
      this.selectedProduct = true
      // 下面這個是點擊縮、放選單
      this.isActive1 = !this.isActive1
      // 重新整理按鈕致能
      this.refreshActive = true
      // 選後把把選擇狀態顯示出來
      this.select_state1 = event.target.innerText
      this.ShowNodeInfoAndRecordWithProduct(this.select_state1)
      // this.GetRecordContentWithoutAuthByNodeTag(this.select_state1, 'product')
      await this.GetGraph(this.select_state1, 2, 3, this.select_state1)
    },
    async clickNodeEvent (nodeName) {
      await this.GetGraphPart(nodeName, 3, 3, this.select_state1)
    },
    async GetGraph (name, uLevel, dLevel, product) {
      this.isLoading = true
      const dataForm = new FormData()
      dataForm.append('name', name)
      dataForm.append('u_level', uLevel)
      dataForm.append('d_level', dLevel)
      dataForm.append('product', product)
      const graphRequest = Axios.create({
        baseURL: Config.graphBackend,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await graphRequest.post('/service/graph', dataForm).then(res => {
        this.current_graph_data = { nodes: res.data.result.node_styles, edges: res.data.result.edges }
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      graphInstance.read(this.current_graph_data)
      this.isLoading = false
    },
    async GetGraphPart (name, uLevel, dLevel, product) {
      this.isLoading = true
      const dataForm = new FormData()
      dataForm.append('name', name)
      dataForm.append('u_level', uLevel)
      dataForm.append('d_level', dLevel)
      dataForm.append('product', product)
      const graphRequest = Axios.create({
        baseURL: Config.graphBackend,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await graphRequest.post('/service/graph', dataForm).then(res => {
        this.part_graph_data = { nodes: res.data.result.node_styles, edges: res.data.result.edges }
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      graphInstance.read(this.part_graph_data)
      this.isLoading = false
    },
    async commitVerify () {
      this.commitVerifyForm = true
    },
    async commitVerify2 () {
      this.commitVerifyForm2 = true
    },
    async cancelCommitVerify () {
      this.commitVerifyForm = false
    },
    async cancelCommitVerify2 () {
      this.commitVerifyForm2 = false
    },
    async generateNodes (product) {
      this.commitVerifyForm = false
      this.isLoading = true
      await UserService.GenerateNewNode(product).then(
        res => {
          this.contents = res.data.result
          // console.log(this.contents)
        }
      ).catch(err => {
        // console.log('generate new nodes error')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      this.isLoading = false
    },
    async generateStyles (product) {
      this.commitVerifyForm2 = false
      this.isLoading = true
      // console.log('loading ', this.isLoading)
      await UserService.GenerateNewGraph(product).then(
        res => {
          this.contents = res.data.result
          // console.log(this.contents)
        }).catch(err => {
        // console.log('generate new graph structure and style error')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      this.isLoading = false
    },
    initG6 (w, h, d) {
      // console.log('generating graph2')
      const data = d
      graphInstance = new G6.Graph({
        container: 'graph',
        fitView: true,
        width: w,
        height: h,
        defaultNode: {
          // fill: '#40a9ff',
          // stroke: '#096dd9'
        },
        defaultEdge: {
          type: 'line',
          style: {
            endArrow: {
              path: G6.Arrow.triangle(10, 10),
              fill: '#413960',
              lineWidth: 3
            },
            stroke: '#413960',
            lineWidth: 1
          }
        },
        layout: {
          type: 'force',
          preventOverlap: true,
          linkDistance: 550
        },
        modes: {
          default: ['drag-canvas', 'zoom-canvas', 'drag-node'] // 允许拖拽画布、放缩画布、拖拽节点
        }
      })
      // graph.read(data)
      // graph.on('node:dragstart', function (e) {
      //   graph.layout()
      //   refreshDragedNodePosition(e)
      // })
      // graph.on('node:drag', function (e) {
      //   refreshDragedNodePosition(e)
      // })
      // graph.on('node:dragend', function (e) {
      //   e.item.get('model').fx = null
      //   e.item.get('model').fy = null
      // })
      // function refreshDragedNodePosition (e) {
      //   const model = e.item.get('model')
      //   model.fx = e.x
      //   model.fy = e.y
      // }
      graphInstance.read(data)
      graphInstance.on('node:dragstart', function (e) {
        graphInstance.layout()
        refreshDragedNodePosition(e)
      })
      graphInstance.on('node:drag', function (e) {
        refreshDragedNodePosition(e)
      })
      graphInstance.on('node:dragend', function (e) {
        e.item.get('model').fx = null
        e.item.get('model').fy = null
      })
      // graphInstance.on('node:click', ev => {
      //   const nodeID = ev.item.id
      //   // console.log(nodeID)
      //   this.showSomething()
      // })
      // 有時間的話會完成，選取他會highlight node並顯示node name
      // // 另外會data紀錄上一個選取的node，選取到另一個就要取消掉上個style
      // on這邊沒有開啟因為，這邊是檢視、檢查用，沒有要做圖譜操作
      // graphInstance.on('node:click', ev => {
      //   // some operations
      //   const nodeInstance = ev.item
      //   this.nodeName = nodeInstance._cfg.id
      //   this.node_selected = true
      //   this.showDetail(this.nodeName)
      //   this.showRecords(this.select_state1, this.nodeName)
      // })

      function refreshDragedNodePosition (e) {
        const model = e.item.get('model')
        model.fx = e.x
        model.fy = e.y
      }
      // // console.log('generating graph3')
    }
  },
  async mounted () {
    this.isLoading = true
    this.GetProductList()
    this.$nextTick(function () {
      // 動態抓取element寬高的function
      // console.log('generating graph1')
      const width = this.$refs.canvas.clientWidth
      const height = this.$refs.canvas.clientHeight
      this.initG6(width, height, this.current_graph_data)
    })
    await this.checkAppConnection()
    this.isLoading = false
  },
  data () {
    return {
      isLoading: false,
      isActive1: false,
      refreshActive: false,
      productList: [],
      select_state1: '請選擇',
      connected: false,
      // 下面這個是for 節點產生
      commitVerifyForm: false,
      // 下面致個是for 樣式產生
      commitVerifyForm2: false,
      nodeInfoList: [],
      part_graph_data: {},
      current_graph_data: {},
      selectedProduct: false
    }
  }
}
</script>

<style scoped>
/* scrollbar背景設置 */
.nodeAndGraphScrollbar::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  /*底色*/
  background: #F5F5F5;
  /*漸層變化*/
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
/* scrollbar滑動controller設置 */
.nodeAndGraphScrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 150px;
  background-color: #413960;
}
/* scrollbar corner設置(上下與左右滑動條會有個交錯正方形空白，設置這個才能處理 */
.nodeAndGraphScrollbar::-webkit-scrollbar-corner{
  background: #F5F5F5;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
/* scrollbar使用提示，使用拉動時候會變色 */
.nodeAndGraphScrollbar::-webkit-scrollbar-thumb:hover {
  background: #85d0a0;
}
.custom-h-inside {
  height: 1000px;
}
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
